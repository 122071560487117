import React from "react";
import USClogo from "./usc-logo.png";
import BROWNRISDlogo from "./brownrisd-logo.png";
import HKUSTlogo from "./hkust-logo.png";

const Education = () => {
  return (
    <div className="each-container" id="edu-container">
      <div className="edu-part">
        <div id="education">
          <div className="title">Education</div>
          <div className="edu-box">
            <a
              href="https://hkust-gz.edu.cn"
              className="logolink"
              target="_blank"
            >
              <div className="edu-logobox">
                <img
                  src={HKUSTlogo}
                  className="edu-logo"
                  alt="./usc-logo.png"
                />
              </div>
            </a>
            <div className="edu-text">
              <div>
                <b>Ph.D in Computational Media and Arts</b>
              </div>
              <div>
                The Hong Kong University of Science and Technology (Guangzhou)
              </div>
              <div className="time">2023 Fall - Present</div>
            </div>
          </div>
          <br></br>

          <div className="edu-box">
            <a
              href="https://design.engineering.brown.edu"
              className="logolink"
              target="_blank"
            >
              <div className="edu-logobox">
                <img
                  src={BROWNRISDlogo}
                  className="edu-logo"
                  alt=".brownrisd-logo.png"
                />
              </div>
            </a>
            <div className="edu-text">
              <div>
                <b>M.A. in Design Engineering (MADE)</b>
              </div>
              <div>
                Brown University & Rhode Island School of Design (RISD) | Joint
                Degree
              </div>
              <div className="time">2022 - 2023</div>
            </div>
          </div>
          <br></br>

          <div className="edu-box">
            <a
              href="https://about.usc.edu/"
              className="logolink"
              target="_blank"
            >
              <div className="edu-logobox">
                <img src={USClogo} className="edu-logo" alt="./usc-logo.png" />
              </div>
            </a>
            <div className="edu-text">
              <div>
                <b>B.A. in Economics & B.A. in Data Science</b>
              </div>
              <div>
                <b>Minor in Customer Analytics</b>
              </div>
              <div>University of Southern California</div>
              <div className="time">2018 - 2022</div>
            </div>
          </div>
        </div>
      </div>
      <div className="intern-part">
        <div id="intern">
          <div className="title">Work Experience</div>
          <div>
            <b>HCI Research Intern</b>
          </div>
          <div>Lenovo Research</div>
          <div className="time">June 2024 - Sept 2024</div>
          <br></br>
          <div>
            <b>Product Manager Intern</b>
          </div>
          <div>JD.com, Inc.</div>
          <div className="time">July 2021 - Aug 2021</div>
          <br></br>
          <div>
            <b>Product Manager Intern</b>
          </div>
          <div>ByteDance</div>
          <div className="time">Sept 2020 - Dec 2020</div>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Education;
