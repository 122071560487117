import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-reveal";
import Footer from "../../components/navbar/footer";
import BUBBLEHEADER from "./bubble-header.png";
import BUBBLE1 from "./1.png";
import BUBBLE4 from "./4.png";
import BUBBLE5 from "./5.png";
import BUBBLE6 from "./6.png";
import BUBBLE10 from "./10.png";
import BUBBLE13 from "./13.png";
import BUBBLE14 from "./14.png";
import BUBBLE15 from "./15.png";
import BUBBLE16 from "./16.png";
import BUBBLE17 from "./17.png";
import BUBBLE18 from "./18.png";
import BUBBLE19 from "./19.png";
import BUBBLE20 from "./20.png";
import BUBBLE21 from "./21.png";
import BUBBLE22 from "./22.png";
import BUBBLE23 from "./23.png";
import BUBBLE24 from "./24.png";
import BUBBLE25 from "./25.png";
import BUBBLE26 from "./26.png";
import BUBBLE31 from "./31.png";
import BUBBLEGIF from "./bubble-working.gif";

const Bubble = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bubble | Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="design-main-container">
          <Fade>
            <Link to="/">
              <div className="backtohome"> Back to Homepage </div>
            </Link>
          </Fade>
          <div className="design-header-sec">
            <Fade bottom>
              <div className="design-name">
                Bubble{" "}
                <span className="design-title-tag">
                  / Experience Design, Interaction Design
                </span>
              </div>
              <div className="design-description">
                Leveraging sound parsing and wearable technology to create a
                more intuitive and effective way to control home devices.
              </div>
              <div className="design-header-div-pebble">
                <img
                  src={BUBBLEHEADER}
                  alt=".pebbleheader.png"
                  className="design-header-img-pebble"
                />
              </div>

              <br></br>
              <div className="design-title-tag">
                Team of 4 | 2023 | 12 weeks | Role: UX Research & Design,
                Interaction Design
              </div>
            </Fade>
          </div>
          <div className="design-container-bubble">
            <img
              src={BUBBLE1}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE4}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />

            <img
              src={BUBBLE5}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE6}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />

            <img
              src={BUBBLE13}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />

            <img
              src={BUBBLE14}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE15}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE16}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE17}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE18}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE19}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE20}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE21}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE22}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE23}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLEGIF}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />

            <img
              src={BUBBLE24}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE25}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE26}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE10}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={BUBBLE31}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <div className="bubble-video">
              <iframe
                height="100%"
                width="100%"
                src="https://player.youku.com/embed/XNTk2NTc3NDQ3Mg=="
                frameborder="0"
              />
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Bubble;
