import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      @2024 by Zhongyue Zhang ✨ <em>"An Invincible Summer Inside Me."</em> 
    </div>
  );
};

export default Footer;
