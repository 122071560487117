import React, { useState } from "react";
import Pebble from "./pebble-cover.png";
import LocalReturn from "./localreturn-cover.png";
import Mirror from "./mirror-cover.gif";
import JD from "./jd-cover.JPG";
import YUEBUBAN from "./yuebuban.png";
import YSI from "./ysi-cover.gif";
import IOT from "./iot.gif";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import OldFriends from "./oldfriends-cover.JPG";

const Projects = () => {
  const el = React.useRef(null);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [icon, setIcon] = useState("fa fa-caret-right");

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<i>human-centered</i>.",
        "<i>research-driven</i>.",

        "<i>seamless experience</i>.",

        "<i>iterative process</i>.",
        "<i>multi-modal</i>.",
      ],
      typeSpeed: 60,

      backSpeed: 10,
      backDelay: 1800,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  function toggleIcon() {
    if (icon === "fa fa-caret-right") {
      setIcon("fa fa-caret-down");
    } else {
      setIcon("fa fa-caret-right");
    }
  }

  return (
    <div className="each-container" id="projects-container">
      <div className="projects-part">
        <div id="projects">
          <div className="title">Design & Engineering Projects</div>
         {/*<div className="update">(🚧 Updating...)</div>*/}
          <div className="category">
            <span className="typewriter">
              <i>#</i> <span ref={el} className="writertext" />
            </span>
          </div>

          <div className="break"> </div>
          <div className="projects-item">
          
            {/*<Link to="/pebble">*/}
              {" "}
              <img src={Pebble} alt="./pebble-cover.png"></img>
              <div className="project-caption">
                <p>
                  <b>Pebble</b> <span className="smallyear">/ 2022</span>
                </p>

                <p className="description">
                  A new experience of urban farming.
                </p>
              </div>{" "}
            {/*</Link>*/}
          </div>
          <div className="projects-item">
           {/* <Link to="/not-mirror">*/}
              <img src={Mirror} alt="./mirror-cover.gif"></img>

              <div className="project-caption">
                <p>
                  <b>Not Mirror</b> <span className="smallyear">/ 2022</span>
                </p>

                <p className="description">Smart furniture product design.</p>
              </div>
              {/*</Link>*/}
          </div>
          <div className="projects-item">
           {/* <Link to="/local-return">*/}
              <img src={LocalReturn} alt="./localreturn-cover.png"></img>
              <div className="project-caption">
                <p>
                  <b>Local Return</b> <span className="smallyear">/ 2022</span>
                </p>

                <p className="description">
                  Creative web design for non-profits.
                </p>
              </div>
           {/* </Link>*/}
          </div>
          <div className="projects-item">
           {/* <Link to="/bubble">*/}
              <img src={IOT} alt="./iot.gif"></img>
              <div className="project-caption">
                <p>
                  <b>Bubble</b> <span className="smallyear">/ 2023</span>
                </p>

                <p className="description">
                  Reimagine the smart home IoT control
                </p>
              </div>{" "}
           {/*</Link>*/}
          </div>
          <div className="projects-item">
           {/* <Link to="https://oldfriends-29c609.webflow.io" target="_blank">*/}
              <img src={OldFriends} alt="./old-friends.gif"></img>
              <div className="project-caption">
                <p>
                  <b>Old Friends Project</b> <span className="smallyear">/ 2023</span>
                </p>

                <p className="description">
                  Making old objects into smart devices
                </p>
              </div>{" "}
             {/* </Link>*/}
          </div>
          {/* <div className="projects-item">
            <img
              src={MagnetImg}
              alt="./magnet-cover.jpg"
              className="greyimg"
            ></img>
            <div className="project-caption">
              <p>
                <b>Soft Fridge Magnets</b>{" "}
                <span className="smallyear">/ 2022</span>
              </p>

              <p className="description">
                Quipu-inspired rountine communicator.
              </p>
            </div>
          </div> */}
          <div className="break"></div>
          
            {/* 
          <div
            id="business-category"
            className="category"
            onClick={() => {
              toggleIcon();
              setToggle(!toggle);
            }}
          >
         
            <span className={`${icon}`}></span> Business/Entrepreneurial
          </div>
          {toggle && (
            <div id="business-box">
              <div className="projects-item">
                <img src={JD} alt="./jd-cover.JPG" id="jdimg"></img>
                <div className="project-caption">
                  <p>
                    <b>JD Retail Insurance Project</b>{" "}
                    <span className="smallyear">/ 2021</span>
                  </p>

                  <p className="description">
                    Ranked Top 1 at summer intern challenge.
                  </p>
                </div>
              </div>
              <div className="projects-item">
                <img src={YSI} alt="./ysi-cover.jif" className="greyimg"></img>
                <div className="project-caption">
                  <p>
                    <b>iLuck Lab</b> <span className="smallyear">/ 2020</span>
                  </p>

                  <p className="description">
                    Sex education gamification app design.
                  </p>
                </div>
              </div>{" "}
              <div className="projects-item">
                <img
                  src={YUEBUBAN}
                  alt="./yuebuban.png"
                  className="greyimg"
                ></img>
                <div className="project-caption">
                  <p>
                    <b>YueBuBan Oat Ball</b>{" "}
                    <span className="smallyear">/ 2019</span>
                  </p>

                  <p className="description">
                    Gen-Z healthy snack for women in China.
                  </p>
                </div>
              </div>
            </div>
          )}*/}
        </div>
        <div className="break"> </div>
      </div>
    </div>
  );
};

export default Projects;
