import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/navbar";
import Education from "../components/education/education";
import Awards from "../components/awards/awards";
import About from "../components/about/about";
import News from "../components/news/news";
import Footer from "../components/navbar/footer";
import Projects from "../components/projects/projects";
import Fun from "../components/fun/fun";
import Research from "../components/research/research";

const AcademicSec = () => {
  useEffect(() => {
    document.title = "Zhongyue (Sherley) Zhang";
    // 设置 meta description
    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      // 如果已存在，则更新内容
      metaDescription.setAttribute("content", "I am a second-year PhD student at HKUST(GZ), with a focus on human-computer interaction (HCI).");
    } else {
      // 如果不存在，则创建新的 meta description 标签
      const description = document.createElement("meta");
      description.name = "description";
      description.content = "I am a second-year PhD student at HKUST(GZ), with a focus on human-computer interaction (HCI).";
      document.head.appendChild(description);
    }
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="page-container">
          <Navbar page="index" />
          <div className="body-part">
            <div>
              <About />
            </div>
            <div>
              <News />
            </div>
            <div>
              <Research />
            </div>
            <div>
              <Projects />
            </div>
            <div>
              <Education />
            </div>
            <div>
              <Awards />
            </div>
            <div>
              <Fun />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AcademicSec;
