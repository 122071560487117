import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-reveal";
import Footer from "../../components/navbar/footer";
import PEBBLEHEADER from "./pebble-header.png";
import PEBBLE1 from "./pebble1.png";
import PEBBLE2 from "./pebble2.png";
import PEBBLE3 from "./pebble3.png";
import PEBBLE4 from "./pebble4.png";
import PEBBLE5 from "./pebble5.png";
import PEBBLE9 from "./pebble9.png";
import PEBBLE6 from "./pebble6.png";
import PEBBLE8 from "./pebble8.png";
import PEBBLE10 from "./pebble10.png";
import PEBBLE11 from "./pebble11.png";
import PEBBLEFILED from "./pebble-field.png";
import PEBBLE15 from "./pebble15.png";
import PEBBLE16 from "./pebble16.png";
import PEBBLE17 from "./PEBBLE17.png";
import PEBBLEJOURNEY from "./PEBBLEJOURNEY.png";
import PEBBLE18 from "./PEBBLE18.png";
import PEBBLESYSTEM from "./PEBBLESYSTEM.png";
import PEBBLE21 from "./PEBBLE21.png";
import PEBBLE22 from "./PEBBLE22.png";
import PEBBLE23 from "./PEBBLE23.png";
import PEBBLELAST from "./pebblelast.png";
import pebbleworks from "./pebbleworks.jpg";

const Pebble = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Pebble | Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="design-main-container">
          <Fade>
            <Link to="/">
              <div className="backtohome"> Back to Homepage </div>
            </Link>
          </Fade>
          <div className="design-header-sec">
            <Fade bottom>
              <div className="design-name">
                Pebble{" "}
                <span className="design-title-tag">
                  / UX & UI Design, Product Design
                </span>
              </div>
              <div className="design-description">
                "Pebble" is the at-home-tea-growing kit to help develop an
                interest in gardening and farming in urban areas. The at-home
                kit is designed to rethink urban farming and create an
                innovative and uplifting experience. The objective of pebble is
                to create a positive social impact by encouraging urban-farming
                habits that can develop into urban culture.
              </div>
              <div className="design-header-div-pebble">
                <img
                  src={PEBBLEHEADER}
                  alt=".pebbleheader.png"
                  className="design-header-img-pebble"
                />
              </div>
              <div className="design-description">
                Collaborated with Boston Mayor's Office of Food Justice
              </div>
              <div className="design-description">
                Featured by the Luxury Tea Brand TEALEAVES:{" "}
                <a
                  href="https://naturexdesign.tealeaves.com/pebble-the-ritual-of-urban-farming/"
                  target="_blank"
                >
                  "Pebble: The Ritual Of Urban Farming"
                </a>
              </div>
              <br></br>
              <div className="design-title-tag">
                Team of 4 | 2022 | 4 weeks | Role: User Research, UX/UI Design
              </div>
            </Fade>
          </div>

          <div className="design-container">
            <div className="design-sec-title">URBAN FARMING</div>
            <div className="process-img-div">
              <div className="design-process-text">
                <p>
                  Urban farming is the cultivation of agricultural products in
                  urban areas which can provide a variety of environmental,
                  social, and economic benefits to urban communities.{" "}
                </p>
              </div>
              <img
                src={PEBBLE1}
                alt=".mirror2.png"
                className="design-process-img"
              />
            </div>
            <div className="design-sec-title">UNDERSTAND THE PROBLEM</div>
            <div className="design-process-text">
              <p>
                From literature review and quantitive research, we established
                that the barrier Pebble would need to solve was the cultural
                hesitancy in participating in urban agriculture.
              </p>
            </div>
            <img
              src={PEBBLE2}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE3}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">PROBLEM STATEMENT</div>
            <div className="design-process-text">
              <p></p>
            </div>
            <img
              src={PEBBLE4}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">
              DESKTOP AND QUALITATIVE RESEARCH
            </div>
            <div className="design-process-text">
              <p>
                We've studied urban farming extensively, such as exploring
                home-suitable crops and its integration with modern interior
                design.
              </p>
            </div>
            <img
              src={PEBBLE5}
              alt=".mirror2.png"
              className="design-process-img"
            />{" "}
            <div className="design-process-text">
              <p>
                We've also interviewed field experts, enhancing our
                understanding of urban plant cultivation's scientific
                requirements, consumer needs, and its interaction with the
                community.
              </p>
            </div>
            <img
              src={PEBBLE9}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">THE EDIBLE FLOWERS</div>
            <div className="design-process-text">
              <p>
                Encouraging urban farming novices to cultivate at home involves
                choosing the right crop category. Utilizing our research and
                creative insights, we identified edible flowers as a compelling
                option. Interaction with urban farming enthusiasts on Facebook
                also showed that edible flowers, often used beyond just salad
                garnishing in sauces, teas, and more, are popular. Given their
                simplicity to grow, aesthetic allure, and culinary benefits,
                edible flowers serve as an optimal choice for beginners in urban
                home farming.
              </p>
            </div>
            <img
              src={PEBBLE6}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE8}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">IDEATIONS</div>
            <div className="design-process-text">
              <p>
                We started by developing several engaging edible flower-related
                concepts, like aligning flower planting with personal calendars
                and incorporating origami structures. Following this, we
                organized a focus group with urban farming novices and those
                with some experience, soliciting their experiences and feelings
                with urban farming and feedback on our designs.
              </p>
            </div>
            <img
              src={PEBBLE10}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                Participants really love the edible flower idea due to the
                aesthetic appeal and the satisfaction derived from harvesting,
                along with the ease of care. Crucial insights were gathered
                about major pain points, such as forgetting to water plants and
                the desire for progress transparency. Users also expressed a
                wish for a sense of community.
              </p>
              <p>
                We decided to engage urban farming novices with edible flowers,
                and aim to develop a physical device that subtly helps with
                their flower cultivation progress. Alongside, we plan to create
                an app that not only visualizes their progress but also connects
                them to the broader community.
              </p>
            </div>
            <img
              src={PEBBLE11}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">
              FIELD RESEARCH: COMMUNITY GARDEN
            </div>
            <div className="design-process-text">
              <p>
                We visited a local urban garden to understand the role and
                community connections of urban farming. It became clear that the
                local community farm isn't merely a farming spot, but also a hub
                for events and social connections. There's a collective
                experience and a waiting list to join the community farm
                reflects the unfulfilled needs and a potential scenario for our
                product and service.
              </p>
            </div>
            <img
              src={PEBBLEFILED}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">FINAL ITERATION</div>
            <div className="design-process-text">
              <p>
                Pebble is a plant monitoring device that is designed to guide
                and support novice urban farmers. Prompting users to water their
                plant based on soil readings created by Pebble. By pairing
                Pebble with its app, it moves the ritual of gardening beyond
                monitoring into community building. Pebble users are connected
                to an online community of other urban gardeners, to share ideas
                and learn from one another.
              </p>
            </div>
            <img
              src={PEBBLEHEADER}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE15}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={pebbleworks}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">SYSTEM INNOVATION</div>
            <div className="design-process-text">
              <p></p>
            </div>
            <img
              src={PEBBLE17}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE18}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLESYSTEM}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                One plant can not sustain a household or an individual. However,
                one plant can connect entire households to the concept and
                ritual of growing their own food. The daily practices of
                growing, watering, and taking care of the plant can inspire and
                remind individuals that their food is grown by others, through
                care and attention. Connection to nature is vital in
                understanding how delicate the process of food production can be
                and fostering a better appreciation for biodiversity. The
                concept of Pebble can help encourage communities to eat
                sustainably and seasonally by making urban agriculture less
                intimidating.{" "}
              </p>
            </div>
            <img
              src={PEBBLEJOURNEY}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">THE FUTURE OF PEBBLE</div>
            <div className="design-process-text">
              <p>
                Through our engagement with the luxury tea brand TEALEAVES, we
                were inspried to forge a meaningful link between urban farming
                and culture-enriched experience through growing tea. From
                cultivation to its consumption, tea is rich in cultural rituals.
                Pebble expands on cultural tea traditions but brings the tea
                ritual into the modern landscape through technology. Growing tea
                can be a way to reach and create a diverse online community
                which can help Pebble better cater its services to people from
                different cultural backgrounds.
              </p>
            </div>
            <img
              src={PEBBLE21}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE22}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={PEBBLE23}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">WHAT PEBBLE HOPES TO ACHIEVE</div>
            <div className="design-process-text">
              <p>
                1. Foster mindfulness with the ritual of growing food. <br></br>
                2. Connect community through cultural rituals of farming and
                consumption. <br></br>3.Elevate resources for local community
                gardens from Pebble purchases.
                <br></br>
                4. Explore biodiversity protection via future models of growing
                different plants in one indoor space.
              </p>
            </div>
            <img
              src={PEBBLELAST}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">USER EXPERIENCE VIDEO</div>
            <div className="design-process-text">
              <p></p>
            </div>
            <iframe
              height="450px"
              width="100%"
              src="https://player.youku.com/embed/XNTk2Njk5ODE2MA=="
              frameborder="0"
            />
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Pebble;
