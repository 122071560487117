import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
//import { ReactComponent as LinkedInIcon } from "./icons/linkedin.svg";
//import { ReactComponent as GoogleScholarIcon } from "./icons/googlescholar.svg";
//import { ReactComponent as GithubIcon } from "./icons/github.svg";
import NameLogo from "./namelogo.png";

const Navbar = ({ page = "index" }) => {
  return (
    <nav>
      <div className="navigation">
        <Link to="/" className="name-brand">
          <img src={NameLogo} className="namelogo" alt="namelogo.png" />
        </Link>
        <div className="menu">
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            About
          </ScrollLink>
          <ScrollLink
            to="news"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            News
          </ScrollLink>
          <ScrollLink
            to="research"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Research
          </ScrollLink>
          <ScrollLink
            to="projects"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Projects
          </ScrollLink>
          <ScrollLink
            to="education"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Experience
          </ScrollLink>

          <ScrollLink
            to="awards"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Awards
          </ScrollLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
