import React, { useState, useEffect } from "react";
import MirrorHeader from "./mirror1.png";
import Research1 from "./research1.png";
import Research2 from "./research2.png";
import Research3 from "./research3.png";
import Research4 from "./research4.png";
import Research5 from "./research5.png";
import Research6 from "./research6.png";
import MirrorColor from "./mirrorcolor.png";
import MirrorCog from "./mirrorcog.png";
import Mirrorshape from "./mirrorshape.png";
import Mike1 from "./mike1.png";
import Mirrorinteration from "./mirror-cover.gif";
import IDEATION from "./ideation.png";
import FORMS from "./mirrorforms.png";
import PROTOTYPE1 from "./prototype1.gif";
import PROTOTYPECO1 from "./prototypeco1.png";
import PROTOTYPE2 from "./prototype2.gif";
import PROTOTYPECO2 from "./prototypeco2.png";
import EXHIBITION from "./final-gif.gif";
import MIRRORME from "./mirrorme.png";
import Footer from "../../components/navbar/footer";
import MIRRORLOGO from "./mirrorlogo.png";
import COLORRESEARCH from "./mirrorcolorresearch.png";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-reveal";

const Mirror = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Not-Mirror | Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="design-main-container">
          <Fade>
            <Link to="/">
              <div className="backtohome"> Back to Homepage </div>
            </Link>
          </Fade>
          <div className="design-header-sec">
            <Fade bottom>
              <div className="design-name">
                Not Mirror{" "}
                <span className="design-title-tag">/ Product Design</span>
              </div>
              <div className="design-description">
                "Not Mirror" is a low key smart shelf that seamlessly blends
                non-intrusive, low-tech solutions with color psychology, serving
                as a subtle reminder for last-minute essentials. It is a in-home
                product designed to foster a seamless, meaningful integration of
                technology into people's lives.
              </div>
              <div className="design-header-div">
                <img
                  src={MirrorHeader}
                  alt=".mirrorheader.png"
                  className="design-header-img"
                />
              </div>
              <div className="design-title-tag">
                Team of 4 | 2022 | 4 weeks | Role: UX Research & Design, Arduino
                Development
              </div>
            </Fade>
          </div>

          <div className="design-container">
            <div className="design-sec-title">THE PROBLEM</div>

            <div className="process-img-div">
              <div className="design-process-text">
                <p>
                  Mike's day started in a rush, his phone buzzed with a weather
                  alert, but he missed it. In his hurry, he left his key behind.
                  At 5:00 pm, he was stuck in heavy rain, soaking his commute
                  home. Arriving at his locked house, he realized his key was
                  still inside. It was a truly awful day.{" "}
                </p>
              </div>
              <img
                src={Research1}
                alt=".mirror2.png"
                className="design-process-img"
              />
            </div>

            <div className="design-sec-title">
              BACKGROUND RESEARCH: the "Hurry Sickness"
            </div>

            <div className="design-process-text">
              <p>
                Mike is not alone. Nowadays, many individuals are grappling with
                "Hurry Sickness", a blend of anxiety and restlessness that
                typically manifests as a persistent sense of urgency.
              </p>
            </div>
            <img
              src={Research2}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                A study by an insurance company revealed that the average
                American spends an aggregate of 2.5 days each year searching for
                misplaced items, a habit that cumulatively costs households $2.7
                billion annually. In light of this, we redirected our attention
                towards the final moments individuals experience each day before
                leaving their homes, a time when the symptoms of "Hurry
                Sickness" are most likely to manifest.
              </p>
            </div>
            <img
              src={Research3}
              alt=".mirror2.png"
              className="design-process-img"
            />

            <div className="design-sec-title">USER RESEARCH</div>

            <div className="design-process-text">
              <p>
                We conducted a user survey and interviews, primarily focusing on
                understanding people's behaviors in the last 30 seconds before
                leaving their homes. The most common tasks people wish to
                accomplish before stepping out include grabbing their keys and
                checking the weather, with the final task often being to turn
                off the lights. Furthermore, there is a general preference to
                keep all essential items in a centralized location at the
                entryway. People aspire to stay organized, however, only a
                handful are currently able to manage it effectively.
              </p>
            </div>
            <img
              src={Research4}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">SYNTHESIZE</div>
            <div className="design-process-text">
              <p>
                Current solutions, encompassing various devices and
                applications, often require users' complete attention,
                increasing their cognitive and emotional burdens. Our aim is to
                enhance the user's everyday routine by addressing these
                limitations inherent in existing solutions. We aspire to design
                a product that seamlessly merges with users' daily activities,
                without augmenting their cognitive or emotional load, hence
                offering a more user-friendly and intuitive approach.
              </p>
            </div>
            <img
              src={Research6}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">THE SOLUTION</div>
            <div className="design-process-text">
              <p>
                Our solution - Not Mirror is a smart shelf that combines
                practicality and aesthetics.
              </p>
            </div>
            <img
              src={Mirrorinteration}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                It prompts users not to forget their keys and other essentials
                by utilizing a vibrant lamp display that indicates the day's
                weather. The display is bifurcated into an upper and a lower
                segment, each portraying a color corresponding to the perceived
                outdoor temperature. The upper segment exhibits the day's high
                temperature, while the lower segment reflects the low
                temperature. Additionally, an encircling ring represents any
                potential precipitation. To activate and appreciate this
                visually pleasing light effect, users simply need to hang their
                keys on the designated hook of the shelf.
              </p>
            </div>
            <img
              src={MirrorColor}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                The aim is for users to cross paths with this device as they
                head out, taking note of the illuminated display that subtly
                alerts them about their essentials while also providing
                pertinent weather information to help with their daily routines.
                With a design that is non-invasive and demands minimal attention
                or operation effort, it's accessible even to those less inclined
                towards adopting smart home technologies. As such, 'Not Mirror'
                serves as a smart device that enhances users' everyday habits
                without imposing any burdens, thus adding value seamlessly.
              </p>
            </div>
            <img
              src={MirrorCog}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-sec-title">SUCCESSFUL USER STORY</div>
            <div className="design-process-text">
              <p></p>
            </div>
            <img
              src={Mike1}
              alt=".mirror2.png"
              className="design-process-img"
            />

            <div className="design-sec-title">PRODUCT DEVELOPMENT</div>
            <div className="design-process-text">
              <p>
                <center>/ Ideation /</center>
              </p>
            </div>
            <img
              src={IDEATION}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                <center>/ Form & Material Exploration /</center>
              </p>
            </div>
            <div className="two-image">
              <img
                src={FORMS}
                alt=".mirror2.png"
                className="design-process-hor"
              />
              <img
                src={Mirrorshape}
                alt=".mirror2.png"
                className="design-process-hor"
              />
            </div>
            <div className="design-process-text">
              <p>
                <center>/ Color Research /</center>
              </p>
            </div>
            <img
              src={COLORRESEARCH}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <div className="design-process-text">
              <p>
                <center>/ Development /</center>
              </p>
            </div>
            <div className="two-image">
              <img
                src={PROTOTYPECO1}
                alt=".mirror2.png"
                className="design-process-ver"
              />
              <img
                src={PROTOTYPE1}
                alt=".mirror2.png"
                className="design-process-ver"
              />
            </div>
            <div className="design-process-text">
              <p>
                <center>/ Prototype /</center>
              </p>
            </div>
            <div className="two-image">
              <img
                src={PROTOTYPECO2}
                alt=".mirror2.png"
                className="design-process-ver"
              />
              <img
                src={PROTOTYPE2}
                alt=".mirror2.png"
                className="design-process-ver"
              />
            </div>
            <div className="design-process-text">
              <p>
                <center>/ Exhibition /</center>
              </p>
            </div>
            <img
              src={EXHIBITION}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={MIRRORME}
              alt=".mirror2.png"
              className="design-process-img"
            />
            <img
              src={MIRRORLOGO}
              alt=".mirror2.png"
              className="design-process-img"
            />
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Mirror;
