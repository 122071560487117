import React from "react";
import Profile2 from "./zzy3.jpg";

const About = () => {
  return (
    <div id="about-container" className="each-container">
      <div className="profile-container">
        <img src={Profile2} alt="./zzy.JPG" className="profile-img" />
      </div>

      <div className="about-part">
        <div id="about">
          <div className="about-hi">
            Hi, I'm <span className="hi-name">Zhongyue "Sherley" Zhang // 张钟月</span>
          </div>
          <br></br>
          <div className="self-intro">
            <div>
              I am a second-year PhD student in{" "}
              <a href="https://cma.hkust-gz.edu.cn" target="_blank">
                Computational Media and Arts (CMA)
              </a>{" "}
              Thrust at{" "}
              <b>
                The Hong Kong University of Science and Technology (Guangzhou)
              </b>
              . I am fortunate to be advised by{" "}
              <a href="https://www.mingmingfan.com" target="_blank">
                Prof. Mingming Fan
              </a>{" "}
              and part of the{" "}
              <a href="https://www.mingmingfan.com/lab/" target="_blank">
                Accessible & Pervasive User EXperience (APEX) Group
              </a>
              .
            </div>
            <br></br>
            <div>
            My research lies at the intersection of <b>Human-Computer Interaction (HCI)</b> and <b>Artificial Intelligence (AI)</b>, 
            focusing on (1) assistive technologies to empower older adults and 
            (2) human-AI collaboration to explore interaction dynamics and design supportive tools. 
            I am also interested in exploring issues related to technology-mediated elderly care.
            </div>
            <br></br>
            <div>
              I was a Master's student in the Design Engineering program at Brown University and Rhode Island School of Design (RISD). I earned my two
              Bachelor's and a minor with <em>Magna Cum Laude</em> from University of Southern California.
            </div>

            <br></br>
            <div>
              Feel free to reach out to me at:{" "}
              zzhang837@connect.hkust-gz.edu.cn
            </div>
            <br></br>
            <div className="social-media">
              <a 
              href="https://scholar.google.com/citations?user=0zAiggUAAAAJ" 
              class="fa fa-google fa-lg"
              target="_blank"></a>
              <a
                href="https://www.linkedin.com/in/zhongyue-zhang/"
                class="fa fa-linkedin fa-lg"
                target="_blank"
              ></a>
              <a
                href="https://www.instagram.com/sherley.zhang/"
                class="fa fa-instagram fa-lg"
                target="_blank"
              ></a>
              <a class="fa fa-github fa-lg"></a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
