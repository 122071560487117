import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-reveal";
import Footer from "../../components/navbar/footer";
import TMAGOTCHI1 from "./Tamagotchi1.png";
import TMAGOTCHI2 from "./Tamagotchi2.png";
import AVAR1 from "./avar1.png";
import AVAR2 from "./avar2.png";

const Drawing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Drawing | Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="design-main-container">
          <Fade>
            <Link to="/">
              <div className="backtohome"> Back to Homepage </div>
            </Link>
          </Fade>

          <div className="design-container-drawing">
            {/* <div className="design-sec-title">Tamagotchi, HUMANgotchi</div>
            <div className="design-process-text">
              <p>
                I drew two posters that showcase different aspects of the iconic
                digital pet toy "Tamagotchi". The first poster showcases a
                physical teardown of the Tamagotchi, exploring its internal
                components and design. The second poster explores the
                relationship between humans and technology, drawing parallels
                between the care and affection people gave to their Tamagotchi
                and the growing interest in giving emotions to robots and
                artificial intelligence. The second poster asks an open-ended
                question: if we rely on technology to give us emotional support,
                are we becoming the Tamagotchi? To further drive this point
                home, I included a little Tamagotchi character holding a
                “Humangotchi”.
              </p>
            </div>
            <img
              src={TMAGOTCHI1}
              alt=".pebbleheader.png"
              className="design-header-img-tama"
            />
            <img
              src={TMAGOTCHI2}
              alt=".pebbleheader.png"
              className="design-header-img-tama"
            />
            <div className="design-process-text">
              <p>(design reference: partically from the post at Xiaohongshu)</p>
            </div> */}

            <div className="design-sec-title">Avatars</div>
            <div className="design-process-text">
              <p>
                Many of my friends are using the avatars I drew as their social
                media profile photos~ 💗
              </p>
            </div>
            <img
              src={AVAR1}
              alt=".pebbleheader.png"
              className="design-process-img"
            />
            <div className="design-process-text-drawing">
              <p>Graduation special versions~ 🎓</p>
            </div>
            <img
              src={AVAR2}
              alt=".pebbleheader.png"
              className="design-process-img"
            />
            <br></br>
          </div>

          <br></br>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Drawing;
