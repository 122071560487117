import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-reveal";
import Footer from "../../components/navbar/footer";
import LRHEADER from "./lr-header.jpg";
import LR1 from "./1.png";
import LR2 from "./2.png";
import LR3 from "./3.png";
import LR4 from "./4.png";
import LR5 from "./5.png";
import LR6 from "./6.png";
import LR7 from "./7.png";
import LR8 from "./8.png";
import LR9 from "./9.png";
import LR10 from "./10.png";
import LR11 from "./11.png";
import LR12 from "./12.png";
import LR13 from "./13.png";
import LR14 from "./14.png";
import LR15 from "./15.png";
import LR18 from "./18.png";
import LR19 from "./19.png";

const LocalReturn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Local Return | Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="design-main-container">
          <Fade>
            <Link to="/">
              <div className="backtohome"> Back to Homepage </div>
            </Link>
          </Fade>
          <div className="design-header-sec">
            <Fade bottom>
              <div className="design-name">
                Local Return{" "}
                <span className="design-title-tag">
                  / Design Consulting, UX Research, Creative Web Design
                </span>
              </div>
              <div className="design-description">
                Local Return is a nonprofit organization dedicated to fostering
                community wealth in Rhode Island through strategic ownership and
                investment initiatives. Our comprehensive user research led to
                the creation of an intuitive, interactive website designed to
                navigate the entirety of the system and address the challenges
                identified. By incorporating gamification, we've heightened the
                engagement potential for prospective investors. Additionally, we
                have streamlined the "Learn-Match-Invest-Feedback" sequence to
                ensure a seamless and enjoyable user experience.
              </div>
              <div className="design-header-div-pebble">
                <img
                  src={LRHEADER}
                  alt=".pebbleheader.png"
                  className="design-header-img-pebble"
                />
              </div>
              <div className="design-description">
                Client: Local Return (Non-profit organization)
              </div>

              <br></br>
              <div className="design-title-tag">
                Team of 3 | 2022 | 4 weeks | Role: UX Research & Design, Data
                Analysis, Presentation
              </div>
            </Fade>
          </div>

          <div className="design-container">
            <div className="design-sec-title">FINAL DECK</div>
            <br></br>
            <img
              src={LR1}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR2}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR3}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR4}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR5}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR6}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR7}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR8}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR9}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR10}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR11}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR12}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR13}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR14}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR15}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />

            <img
              src={LR18}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <img
              src={LR19}
              alt=".pebbleheader.png"
              className="design-header-img-pebble"
            />
            <div className="design-sec-title">FIGMA PROTOTYPE</div>
            <br></br>
            <iframe
              style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
              width="100%"
              height="600"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FocHjffFXJ7DHGagWGHNTvi%2FLOCAL-RETURN-PROTOTYPE%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D279%252C171%252C0.16%26scaling%3Dcontain%26starting-point-node-id%3D1%253A2"
              allowfullscreen
            ></iframe>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default LocalReturn;
