import "./styles/styles.css";
import "./styles/designs.css";
import AcademicSec from "./sections/academic-section";
import { Route, Routes } from "react-router-dom";
import Mirror from "./designs/not-mirror/not-mirror";
import Pebble from "./designs/pebble/pebble";
import LocalReturn from "./designs/local-return/local-return";
import Bubble from "./designs/bubble/bubble";
import Drawing from "./designs/drawing/drawing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<AcademicSec />} />
      <Route path="/not-mirror" element={<Mirror />} />
      <Route path="/pebble" element={<Pebble />} />
      <Route path="/local-return" element={<LocalReturn />} />
      <Route path="/bubble" element={<Bubble />} />
      <Route path="/drawing" element={<Drawing />} />
      <Route />
    </Routes>
  );
}
export default App;
